import AgentActivity from 'models/agent_activity';
import changeCurrentLocation from 'actions/lib/change_current_location';
import SetAndLogActivity from 'actions/agent_activity/set_and_log_activity';
import Snippet, { SnippetContent, SnippetChannels } from 'models/answers/snippet';
import SnippetEditor from 'models/location/snippet_editor';

export default class SwitchSnippetNew {
  constructor(context) {
    this.context = context;
  }

  run({ referencedAnswerId } = {}) {
    this.updateCurrentLocation(referencedAnswerId);
    this.setSnippetComposition();
  }

  updateCurrentLocation(referencedAnswerId) {
    changeCurrentLocation(this.context, SnippetEditor.create({ referencedAnswerId }));
    this.context.executeAction(SetAndLogActivity, { type: AgentActivity.Type.KNOWLEDGE_BASE });
  }

  setSnippetComposition() {
    let snippet = Snippet.create({
      contents: [
        SnippetChannels.create({
          anyChannel: SnippetContent.create(),
          info: SnippetContent.create(),
          message: SnippetContent.create(),
          selfService: SnippetContent.create(),
          language: 'en-us',
        }),
      ],
    });
    this.context.stores.snippetComposition.set(snippet);
    this.context.stores.snippetComposition.clearErrors();
    this.context.stores.snippetComposition.resetPending();
  }
}
