import classnames from 'classnames';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { MenuItem } from 'components/lib/menu_deprecated';
import TranslationLanguages from 'models/languages/translation_languages';
import TranslateIntoSearchMenuContainerWrapper from './translate_into_search_menu_container_wrapper';
import styled from 'styled-components';

export default function TranslateIntoMenuItem({ onSelect, onClose, lastSelectedLanguage, onOpenSearch }) {
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const handleSelectLanguage = code => {
    onSelect?.(code);
    onClose?.();
  };

  const handleOpenSearch = () => {
    setIsSearchOpen(true);
    onOpenSearch?.();
  };

  return (
    <>
      {isSearchOpen ? (
        <TranslateIntoSearchMenuContainerWrapper
          availableLanguages={TranslationLanguages}
          onSelectLanguage={handleSelectLanguage}
        />
      ) : (
        <>
          {lastSelectedLanguage &&
            TranslationLanguages[lastSelectedLanguage] &&
            lastSelectedLanguage !== 'en' &&
            lastSelectedLanguage !== 'es' && (
              <StyledMenuItem
                className={classnames('conversationItemMenu-menuItem', 'conversationItemMenu-menuItem-language')}
                data-aid={`translate-into-lastSelectedLanguage-${lastSelectedLanguage}`}
                onSelect={() => handleSelectLanguage(lastSelectedLanguage)}
              >
                <span>{TranslationLanguages[lastSelectedLanguage]}</span>
              </StyledMenuItem>
            )}
          <StyledMenuItem
            className={classnames('conversationItemMenu-menuItem', 'conversationItemMenu-menuItem-language')}
            data-aid="translate-into-en"
            onSelect={() => handleSelectLanguage('en')}
          >
            <span>English</span>
          </StyledMenuItem>
          <StyledMenuItem
            className={classnames('conversationItemMenu-menuItem', 'conversationItemMenu-menuItem-language')}
            data-aid="translate-into-es"
            onSelect={() => handleSelectLanguage('es')}
          >
            <span>Spanish</span>
          </StyledMenuItem>
          <StyledMenuItem
            className={classnames('conversationItemMenu-menuItem')}
            data-aid="translate-into-see-more"
            onSelect={handleOpenSearch}
          >
            <span>See more</span>
          </StyledMenuItem>
        </>
      )}
    </>
  );
}

const StyledMenuItem = styled(MenuItem)`
  padding: 8px 36px;
  position: relative;

  &:focus {
    padding: 8px 36px;

    svg {
      fill: ${p => p.theme.colors.green400};
    }
  }
`;
TranslateIntoMenuItem.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  lastSelectedLanguage: PropTypes.string.isRequired,
  onOpenSearch: PropTypes.func,
};
