import classnames from 'classnames';
import React from 'react';

import InlineAnnotatedContentContainer from 'components/lib/inline_annotated_content_container';
import { BubbleContent } from 'components/customer/conversation_history/conversation_items_v2/content/bubble_content';
import { ItemContent } from 'components/customer/conversation_history/conversation_items_v2/item_content';
import ItemMetadata from 'components/customer/conversation_history/conversation_items_v2/item_metadata';
import VoiceAIPhoneMetadataIcon from 'components/lib/icons/voiceai_phone_metadata_icon';

export default function VoiceAIMessageItem({ className, isFirstInStack, isLastInStack, isStacked, item }) {
  return (
    <ItemContent
      isFirstInStack={isFirstInStack}
      isLastInStack={isLastInStack}
      isStacked={isStacked}
      item={item}
      showIcon={!isStacked}
    >
      <ItemMetadata
        appName={'Sidekick'}
        icon={<VoiceAIPhoneMetadataIcon />}
        item={item}
        showMetadata={!isStacked}
        text="said"
      />
      <BubbleContent
        className={classnames('voiceAIMessageItem-content', className)}
        data-aid={`voiceAIMessageItem-${item.id}`}
        item={item}
      >
        <div dir="auto">
          <InlineAnnotatedContentContainer
            hasRedactedPaymentCard={item.content.hasRedactedPaymentCardNumber}
            id={item.id}
            isRedacted={item.content.isRedacted}
            text={item.content.body}
          />
        </div>
      </BubbleContent>
    </ItemContent>
  );
}
