import { CardTemplateReader, renderTemplateNodes } from '@gladly/card-renderer';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import _ from 'lodash';

import connect from 'components/lib/connect';
import ExpandableProfileCard from 'components/customer/profile/expandable_profile_card';
import { getCurrentCustomerState } from 'components/customer/summary/lib/store_helpers';
import ProfileCardDef from 'models/configuration/profile_card_def';
import ProfileErrorCard from 'components/customer/profile/profile_error_card';
import Spinner from 'components/common/spinner_two';
import { useOnUnmount } from 'components/hooks/lifecycle_hooks';

const DEFAULT_EXPAND_THRESHOLD = 5;

export function ExternalAppCardBase(props) {
  const { cardConfiguration, cardTemplate, data, isEnabled, isLoading, hasErrors } = props;
  const rendererState = useRef({});
  useOnUnmount(() => {
    rendererState.current = {};
  });

  // Quick sanity check on the card configuration
  const appNamespace = _.trim(_.get(cardConfiguration, 'appNamespace'));
  const cardId = _.trim(_.get(cardConfiguration, 'externalAppCardId'));
  const limits = _.get(cardConfiguration, 'templateData.responseTransforms.limit');
  const sorts = _.get(cardConfiguration, 'templateData.responseTransforms.sort');
  if (!appNamespace || !cardId) {
    return (
      <ProfileErrorCard
        data-aid="external-app-card-configuration-error"
        reason="The card configuration is incorrect."
      />
    );
  }

  // Check if we need to hide (or if there were loading errors)
  if (!isEnabled || (_.isEmpty(data) && !isLoading && !hasErrors)) return null;
  if (hasErrors) {
    return (
      <ProfileErrorCard
        data-aid={`external-app-card-loading-error-${appNamespace}-${cardId}`}
        reason="Unable to load required data."
      />
    );
  }

  // Are we still waiting for the data? Display the spinner and quit
  const dataAid = `external-app-card-${appNamespace}-${cardId}`;
  if (isLoading) {
    return (
      <ExpandableProfileCard data-aid={dataAid} isEmpty isLoading>
        <Spinner key="loading-spinner" />
      </ExpandableProfileCard>
    );
  }

  // If we are here, we need to render the template. Get the data nice and ready first.
  const templateData = transformDataForDisplay(data, limits, sorts);

  // Now try getting the card title and other template meta
  let expanderLimit = DEFAULT_EXPAND_THRESHOLD;
  let cardTitle = '';
  let renderedNodes = [];
  try {
    const reader = new CardTemplateReader({ preprocessTemplate: true });
    const { title, expandThreshold } = reader.extractCardParameters(cardTemplate, templateData);
    const threshold = Number(expandThreshold);

    cardTitle = _.trim(title);
    expanderLimit = _.isNaN(threshold) || threshold <= 0 ? DEFAULT_EXPAND_THRESHOLD : threshold;
    renderedNodes = renderTemplateNodes({
      template: cardTemplate,
      data: templateData,
      hostConfig: {},
      options: {
        preprocessTemplate: true,
      },
      stateStore: rendererState.current,
    });

    // Do we have anything to render? In not, do not display the card
    if (_.isEmpty(renderedNodes)) return null;
  } catch {
    return (
      <ProfileErrorCard
        data-aid="external-app-card-configuration-error"
        reason="The card configuration is incorrect."
      />
    );
  }

  return (
    <ExpandableProfileCard
      data-aid={dataAid}
      isEmpty={false}
      isLoading={false}
      limit={expanderLimit || undefined}
      title={cardTitle}
    >
      {renderedNodes}
    </ExpandableProfileCard>
  );
}

ExternalAppCardBase.propTypes = {
  cardConfiguration: PropTypes.object.isRequired,
  cardTemplate: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object, PropTypes.array]),
  hasErrors: PropTypes.bool,
  isEnabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

function mapStateToProps({ getProvider }, { profileCardDef }) {
  const { customerId, isCustomerLoaded } = getCurrentCustomerState(getProvider);
  const dataStoreProvider = getProvider('externalAppCardData');
  const templateProvider = getProvider('externalAppCardTemplates');

  if (!customerId || !isCustomerLoaded) {
    // Hide the card until the customer profile is loaded, we know the `customerId`, and
    // the customers store is in place
    return {
      isEnabled: false,
    };
  }

  const cardConfiguration = _.get(profileCardDef, 'properties.cardParameters') || {};
  const appCardId = _.trim(cardConfiguration.externalAppCardId);
  if (!appCardId) {
    // Check for [unlikely] misconfiguration
    return {
      hasErrors: true,
      isEnabled: true,
    };
  }

  const isLoading = templateProvider.isLoading() || dataStoreProvider.isLoading({ requester: appCardId });
  const isRequestError =
    !_.isEmpty(templateProvider.getErrorForLoading()) ||
    !_.isEmpty(dataStoreProvider.getErrorForLoading({ requester: appCardId }));
  const cardTemplateInfo = getCardTemplateInfo(templateProvider, appCardId);
  const cardTemplate = _.trim(cardTemplateInfo?.template);
  const dataEnvelope = getCardDataEnvelope(dataStoreProvider, appCardId);

  return {
    cardConfiguration,
    cardTemplate,
    data: dataEnvelope?.data,
    hasErrors: isRequestError || !_.isEmpty(cardTemplateInfo?.errors) || !_.isEmpty(dataEnvelope?.errors),
    isEnabled: templateProvider.isLoading() || !!cardTemplate,
    isLoading,
  };
}

// ==================================== HELPERS =================================

function getCardTemplateInfo(storeProvider, cardId) {
  if (storeProvider.isLoading()) return;

  const items = storeProvider.findAll();
  return _.find(items, templateInfo => templateInfo.externalAppCardId === cardId);
}

function getCardDataEnvelope(storeProvider, cardId) {
  if (storeProvider.isLoading({ requester: cardId })) return;
  return storeProvider.has({ id: cardId }) ? storeProvider.find(cardId) : undefined;
}

function transformDataForDisplay(data, limits, sorts) {
  // TODO [sc-203946]
  return data;
}

// ================================= CONNECTED COMPONENT ========================

const ConnectedExternalAppCard = connect(mapStateToProps)(ExternalAppCardBase);
ConnectedExternalAppCard.propTypes = {
  profileCardDef: PropTypes.instanceOf(ProfileCardDef).isRequired,
};

export default ConnectedExternalAppCard;
