import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import ConversationItemMenuItems from './conversation_item_menu_items';

import AIActivityItem from 'models/ai_activity/ai_activity_item';
import AddNoReplyNeeded from 'actions/conversation_item/no_reply_needed/add_no_reply_needed';
import AddReaction from 'actions/conversation_item/reaction/add_reaction';
import ChatMessage from 'models/chat_message';
import connect from 'components/lib/connect';
import ConversationItem from 'models/conversation_item';
import ConversationMessage, { MessageChannelType } from 'models/conversation_message';
import ConversationNote from 'models/conversation_note';
import CopyItemLink from 'actions/composition/copy_item_link';
import CustomChannelMessage from 'models/custom_channel_message';
import EditTaskItem from 'actions/composition/edit_task_item';
import EmailMessage from 'models/email_message';
import FbMessageIncoming from 'models/fb_message_incoming';
import FollowTask from 'actions/task_follower/follow_task';
import ForwardEmail from 'actions/composition/forward_email';
import HighlightGroupedMessages from 'actions/composition/highlight_grouped_messages';
import InteractionType, { getInteractionType } from 'models/interaction_type';
import ItemContext from 'components/customer/conversation_history/conversation_items_v2/item_context';
import ItemsContext from 'components/customer/conversation_history/conversation_items_v2/items_context';
import OpenModal from 'actions/modal/open_modal';
import OpenOrRefreshCommentPanel from 'actions/task/open_or_refresh_comment_panel';
import RecordingStatus from 'models/recording_status';
import PhoneCall from 'models/phone_call';
import RedactConversationItem from 'models/modal/redact_conversation_item';
import RemoveReaction from 'actions/conversation_item/reaction/remove_reaction';
import ReplyToEmail from 'actions/composition/reply_to_email';
import ReplyToGroupMessage from 'actions/composition/reply_to_group_message';
import ReportPoorCallQuality from 'actions/conversation_item/phone_call/report_poor_call_quality';
import ShowAddObserverToCallModal from 'actions/conversation/show_add_observer_to_call_modal';
import ShowDeleteVoiceRecordingModal from 'actions/conversation/show_delete_voice_recording_modal';
import SmsMessage from 'models/sms_message';
import Task from 'models/task';
import TaskAnalyticsLocations from 'actions/conversation_item/task/task_analytics_locations';
import TranslateConversationItem from 'actions/conversation_item/translate_conversation_item';
import TranslationLanguages from 'models/languages/translation_languages';
import UnfollowTask from 'actions/task_follower/unfollow_task';
import VoiceAIRecording from 'models/voiceai_recording';
import Voicemail from 'models/voicemail';

function mapStateToProps(
  context,
  { currentAgentId, item, isNoReplyNeededVisible, onToggleTranslatedText, isViewTranslation }
) {
  const isEmailItem = item.content instanceof EmailMessage;
  const canManageVoiceRecordings = canManageRecordings(context, item);
  const listenToCallsEnabled = context.isFeatureEnabled('listenToCalls');
  const isViewOriginalEmailHtmlEnabled = context.isFeatureEnabled('viewOriginalEmailHtml');
  const isTranslateIntoEnabled = context.isFeatureEnabled('translationAI');
  const isLivePhoneCall = item.content instanceof PhoneCall && item.content.isLive();
  const isActiveOnCall = item.content instanceof PhoneCall && item.content.isActiveParticipant(currentAgentId);
  const canObserveCall = listenToCallsEnabled && isLivePhoneCall && !isActiveOnCall;
  const isLinkItem = item.isLink();
  const translatedLanguage = TranslationLanguages[item.translation?.contentLanguage];
  const isTask = item.content instanceof Task;
  let isFollowVisible = false;
  let isUnfollowVisible = false;
  const taskInfoProvider = context.getProvider('taskInfo');

  const customerId = context.getProvider('profile').get()?.id;
  const highlightedMessages = context.getProvider('highlightedMessages').get();
  const groupId = getMessageGroupId(item);
  const isHighlighted = highlightedMessages?.id === groupId && highlightedMessages?.customerId === customerId;

  if (isTask) {
    let taskInfo = taskInfoProvider.findBy({ id: item.id });
    if (!taskInfo || !_.some(taskInfo.getFollowers(), f => f.agentId === currentAgentId)) {
      isFollowVisible = true;
    } else {
      isUnfollowVisible = true;
    }
  }
  const canReportPoorCallQuality = canAgentReportPoorCallQuality(context, item);

  return {
    canManageVoiceRecordings,
    canObserveCall,
    canRedactItem: canRedactItem(context, item),
    canReportPoorCallQuality,
    contentType: item.contentType(),
    conversationItemId: item.id,
    customerId,
    isFollowVisible,
    isForwardVisible: isEmailItem,
    isGroupMessagingItem: canGroupItems(context, item),
    isHighlighted,
    isMenuVisible: !isLinkItem,
    isNoReplyNeededVisible,
    isInbound: item.isInbound(),
    isReactionVisible: canReact(item),
    isReplyVisible: isEmailItem,
    isUnfollowVisible,
    isUnreactionVisible: canUnreact(item),
    isViewOriginalEmailHtmlVisible: isEmailItem && isViewOriginalEmailHtmlEnabled,
    isViewTranslation,
    isTranslateIntoVisible: isTranslateIntoEnabled && item.isTranslatable(),
    isToggleTranslationVisible: isTranslateIntoEnabled && item.isTranslated() && !!onToggleTranslatedText,
    recordingUrl: canManageVoiceRecordings ? getRecordingUrl(context, item) : null,
    translatedLanguage,
  };
}

function canGroupItems({ getProvider }, item) {
  const groupId = getMessageGroupId(item);

  if (!groupId) {
    return false;
  }

  if (item.content instanceof CustomChannelMessage) {
    const customChannelProvider = getProvider('customChannels');
    const customChannel = customChannelProvider.findBy({ id: item.content.customChannelId });
    return customChannel && customChannel.messageGroupingEnabled;
  }

  return false;
}

export function getMessageGroupId(item) {
  return item.content instanceof CustomChannelMessage ? item.content.groupId : undefined;
}

function canRedactItem(context, item) {
  const itemContent = _.get(item, 'content');
  const isRedacted = _.get(itemContent, 'isRedacted');

  return (
    itemContent &&
    !isRedacted &&
    context.isFeatureEnabled('complianceManagement') &&
    (itemContent instanceof AIActivityItem ||
      (itemContent instanceof ChatMessage && itemContent.canRedact()) ||
      itemContent instanceof EmailMessage ||
      itemContent instanceof FbMessageIncoming ||
      itemContent instanceof SmsMessage ||
      itemContent instanceof ConversationMessage ||
      itemContent instanceof ConversationNote ||
      itemContent instanceof CustomChannelMessage)
  );
}

function canAgentReportPoorCallQuality(context, item) {
  if (item.content instanceof PhoneCall) {
    const currentAgent = context.getProvider('currentAgent').get();
    const hasCallId = !!item.content.callId;
    const isAgentParticipant = currentAgent && !!item.content.findParticipantById(currentAgent.id);
    return hasCallId && isAgentParticipant;
  }

  return false;
}

function canManageRecordings(context, item) {
  if (!context.isFeatureEnabled('manageVoiceRecordings')) {
    return false;
  }

  if (item.content instanceof PhoneCall && !item.content.isAbandonedCall()) {
    return item.content.recordingStatus === RecordingStatus.AVAILABLE;
  }

  if (item.content instanceof Voicemail || item.content instanceof VoiceAIRecording) {
    return item.content.recordingStatus === RecordingStatus.AVAILABLE;
  }

  return false;
}

function getRecordingUrl(context, item) {
  let orgId = context
    .getProvider('auth')
    .get()
    .getOrgId();
  let baseUrl = `${window.location.origin}/api/v1/orgs/${orgId}/voice`;

  if (item.content instanceof PhoneCall) {
    return `${baseUrl}/call-recordings/${item.content.recordingId}`;
  }

  if (item.content instanceof VoiceAIRecording) {
    return `${baseUrl}/voice-ai-recordings/${item.content.recordingId}`;
  }

  return `${baseUrl}/voicemail-recordings/${item.content.recordingId}`;
}

function canReact(item) {
  const supportedChannels = [MessageChannelType.INSTAGRAM_DIRECT];
  // The reaction menu item should be present for Customer items of Conversation Item
  // type with a channel of Instagram Direct (more in the future) and that do not already
  // have a reaction
  return !!(
    item.isCustomerItem() &&
    item.content instanceof ConversationMessage &&
    supportedChannels.includes(item.content.channel) &&
    !item.content.hasReaction()
  );
}

function canUnreact(item) {
  const supportedChannels = [MessageChannelType.INSTAGRAM_DIRECT];
  // The reaction menu item should be present for Customer items of Conversation Item
  // type with a channel of Instagram Direct (more in the future) and that do not already
  // have a reaction
  return !!(
    item.isCustomerItem() &&
    item.content instanceof ConversationMessage &&
    supportedChannels.includes(item.content.channel) &&
    item.content.hasReaction()
  );
}

function mapExecuteToProps(executeAction, props) {
  return {
    onForward: () => executeAction(ForwardEmail, { item: props.item }),
    onReply: () => executeAction(ReplyToEmail, { item: props.item }),
    onReaction: () => executeAction(AddReaction, { item: props.item }),
    onUnreaction: () => executeAction(RemoveReaction, { item: props.item }),
    onNoReplyNeeded: () => executeAction(AddNoReplyNeeded, {}),
    onEditTask: () => executeAction(EditTaskItem, { item: props.item }),
    onDeleteRecording: () => {
      executeAction(ShowDeleteVoiceRecordingModal, {
        customerId: props.customerId,
        conversationItemId: props.item.id,
        recordingType: props.item.contentType(),
      });
    },
    onObserveCall: () => {
      executeAction(ShowAddObserverToCallModal, {
        customerId: props.customerId,
        conversationItemId: props.item.id,
      });
    },
    onReportPoorCallQuality: () => {
      executeAction(ReportPoorCallQuality, {
        customerId: props.customerId,
        conversationItem: props.item,
      });
    },
    onCopyLink: () => executeAction(CopyItemLink, { item: props.item }),
    onFollowTask: () => executeAction(FollowTask, { itemId: props.item.id }),
    onReplyToGroupMessage: () => executeAction(ReplyToGroupMessage, { item: props.item }),
    onHighlightGroupedMessages: () =>
      executeAction(HighlightGroupedMessages, {
        customerId: props.customerId,
        id: getMessageGroupId(props.item),
        isHighlighted: true,
      }),
    onUnselectHighlightGroupedMessages: () =>
      executeAction(HighlightGroupedMessages, {
        customerId: props.customerId,
        id: getMessageGroupId(props.item),
        isHighlighted: false,
      }),
    onUnfollowTask: () => executeAction(UnfollowTask, { itemId: props.item.id }),
    openSidePanel: () =>
      executeAction(OpenOrRefreshCommentPanel, {
        itemId: props.item.id,
        location: TaskAnalyticsLocations.CONVERSATION_ITEM_MENU,
      }),
    onRedact: () =>
      executeAction(
        OpenModal,
        new RedactConversationItem({
          itemId: props.item.id,
          customerId: props.customerId,
        })
      ),
    onTranslateInto: targetLanguage =>
      executeAction(TranslateConversationItem, {
        conversationItem: props.item,
        customerId: props.customerId,
        targetLanguage,
      }),
  };
}

export const ConversationItemMenuContainer = connect(mapStateToProps, mapExecuteToProps)(ConversationItemMenuItems);
ConversationItemMenuContainer.propTypes = {
  customerId: PropTypes.string.isRequired,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  isNoReplyNeededVisible: PropTypes.bool,
  onToggleTranslatedText: PropTypes.func,
  isViewTranslation: PropTypes.bool,
};

function mapWrappedStateToProps({ getProvider }, props) {
  let currentAgent = getProvider('currentAgent').get();
  return {
    customerId: getProvider('currentLocation').get().customerId,
    currentAgentId: _.get(currentAgent, 'id'),
    isNoReplyNeededVisible: isNoReplyNeededVisible(),
    ...props,
  };

  function isNoReplyNeededVisible() {
    const { lastCustomerItemId, latestConversation, item } = props;
    if (item.id === lastCustomerItemId && isNRNSupported(item)) {
      return true;
    }

    if (hasSla(latestConversation)) {
      if (latestConversation?.sla?.setByConversationItemId === item.id || item.id === lastCustomerItemId) {
        return true;
      }
    }

    return false;
  }

  function isNRNSupported(conversationItem) {
    let unsupportedSessionTypes = [InteractionType.PHONE_CALL, InteractionType.TASK];
    return (
      unsupportedSessionTypes.indexOf(getInteractionType(conversationItem.contentType(), conversationItem.content)) ===
      -1
    );
  }

  function hasSla(conversation) {
    return !!conversation?.sla?.dueAt;
  }
}

const WrappedConversationItemMenuContainer = connect(mapWrappedStateToProps)(ConversationItemMenuContainer);

WrappedConversationItemMenuContainer.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  lastCustomerItemId: PropTypes.string,
  latestConversation: PropTypes.object,
  onOpenPinToCustomer: PropTypes.func,
  onOpenTranslateMenu: PropTypes.func,
  onToggleTranslatedText: PropTypes.func,
  isViewTranslation: PropTypes.bool,
};

export default function ItemMenuContainer(props) {
  const { toggleViewTranslation, isViewTranslation } = useContext(ItemContext);
  const { lastCustomerItem, latestConversation } = useContext(ItemsContext);

  return (
    <WrappedConversationItemMenuContainer
      isViewTranslation={isViewTranslation}
      lastCustomerItemId={lastCustomerItem?.id}
      latestConversation={latestConversation}
      onToggleTranslatedText={toggleViewTranslation}
      {...props}
    />
  );
}
