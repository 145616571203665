import AgentActivity from 'models/agent_activity';
import changeCurrentLocation from 'actions/lib/change_current_location';
import DtoConverter from 'scripts/application/dto_converters/recommended_answer_converter';
import { handleCommonErrors } from 'scripts/application/lib/gateway_error_interactive_handler';
import SetAndLogActivity from 'actions/agent_activity/set_and_log_activity';
import ShowToast from 'actions/toast_deprecated/show_toast';
import Snippet, { SnippetChannels } from 'models/answers/snippet';
import SnippetContent from 'models/answers/snippet_content';
import SnippetEditor from 'models/location/snippet_editor';
import { ToastType } from 'models/toast_deprecated';
import { NotExistError } from 'scripts/application/lib/error';

export default class SwitchSnippetRecommendedAnswer {
  constructor(context) {
    this.context = context;
  }

  run({ recommendedAnswerId, referencedAnswerId }) {
    if (!recommendedAnswerId) {
      this.navigateWithoutRecommendedAnswer(referencedAnswerId);
      return;
    }

    this.context.stores.snippetComposition.setLoading();
    changeCurrentLocation(this.context, SnippetEditor.create({ recommendedAnswerId, referencedAnswerId }));
    this.context.executeAction(SetAndLogActivity, { type: AgentActivity.Type.KNOWLEDGE_BASE });

    return this.context.gateways.recommendedAnswers
      .fetch({ recommendedAnswerId })
      .then(response => {
        const recommendedAnswer = DtoConverter.fromDto(response);

        if (!referencedAnswerId) {
          changeCurrentLocation(
            this.context,
            SnippetEditor.create({ recommendedAnswerId, referencedAnswerId: recommendedAnswer.answer.id })
          );
        }

        const audienceIds = recommendedAnswer.audiences.map(audience => audience.id);
        const snippetWithPublicContent = Snippet.create({
          id: recommendedAnswer.id,
          name: recommendedAnswer.name,
          audienceIds,
          contents: [
            SnippetChannels.create({
              selfService: SnippetContent.create({
                name: recommendedAnswer.name,
                bodyHtml: recommendedAnswer.content,
              }),
            }),
          ],
        });

        this.context.stores.snippetComposition.set(snippetWithPublicContent);
        this.context.stores.snippetComposition.clearErrors();
        this.context.stores.snippetComposition.resetLoading();
      })
      .catch(errDto => {
        this.navigateWithoutRecommendedAnswer(referencedAnswerId);

        if (errDto instanceof NotExistError) {
          this.context.executeAction(ShowToast, {
            type: ToastType.ERROR,
            message: 'This Recommended Answer does not exist.',
          });
        } else {
          handleCommonErrors(this.context, errDto);
        }
      });
  }

  navigateWithoutRecommendedAnswer(referencedAnswerId) {
    this.context.stores.snippetComposition.resetLoading();
    this.context.router.navigateTo(SnippetEditor.create({ referencedAnswerId }));
  }
}
