import React, { useMemo, useState } from 'react';

const ItemContext = React.createContext({
  itemId: null,
});
export default ItemContext;

export function ItemContextProvider({ children, item }) {
  const [isViewTranslation, setViewTranslation] = useState(true);
  const toggleViewTranslation = () => {
    setViewTranslation(!isViewTranslation);
  };

  const value = useMemo(
    () => ({
      item,
      isViewTranslation,
      toggleViewTranslation,
    }),
    [item, isViewTranslation, toggleViewTranslation]
  );

  return <ItemContext.Provider value={value}>{children}</ItemContext.Provider>;
}
