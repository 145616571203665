import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';

import AgentProfile from 'models/agent_profile';
import connect from 'components/lib/connect';

const CurrentAgentContext = React.createContext({});
export default CurrentAgentContext;

export function CurrentAgentProviderBase({ currentAgent, localAgentLanguage, children }) {
  const value = useMemo(() => ({ currentAgent, localAgentLanguage }), [currentAgent, localAgentLanguage]);

  return <CurrentAgentContext.Provider value={value}>{children}</CurrentAgentContext.Provider>;
}

CurrentAgentContext.propTypes = {
  children: PropTypes.any,
  currentAgent: PropTypes.instanceOf(AgentProfile),
  localAgentLanguage: PropTypes.string,
};

export const CurrentAgentProvider = connect(mapStateToProps)(CurrentAgentProviderBase);

function mapStateToProps({ getProvider }) {
  return {
    currentAgent: getProvider('currentAgent').get(),
    localAgentLanguage: getProvider('localAgentLanguage').get()?.code,
  };
}

export function useCurrentAgent() {
  return useContext(CurrentAgentContext);
}
