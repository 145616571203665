import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Agent from 'models/agent';
import connect from 'components/lib/connect';
import ConversationItem, { getOutgoingCommunicationStatus } from 'models/conversation_item';
import Metadata from './metadata';

export const StyledIconAPI = styled.span`
  background-color: ${p => p.theme.colors.purple500};
  border-radius: ${p => p.theme.borderRadius.default};
  color: ${p => p.theme.colors.white};
  font-size: 8px;
  font-weight: ${p => p.theme.fontWeight.medium};
  margin-right: 6px;
  padding: 2px 4px;
  vertical-align: text-top;
`;

export function ApiMetadata({ agent, item, isDemoActive, prepositionalPhrases, status, text, icon, appName }) {
  let metadataIcon = <StyledIconAPI className="api-icon">APP</StyledIconAPI>;
  if (isDemoActive) {
    metadataIcon = undefined;
  } else if (icon) {
    metadataIcon = icon;
  }

  let subject = appName || item.content.sourceName;
  if (isDemoActive) {
    subject = agent ? agent.name : subject;
  }

  return (
    <Metadata
      icon={metadataIcon}
      isInbound={false}
      prepositionalPhrases={prepositionalPhrases}
      status={status ? status : getOutgoingCommunicationStatus(item)}
      subject={subject}
      text={text}
      timestamp={item.timestamp}
    />
  );
}

ApiMetadata.propTypes = {
  agent: PropTypes.instanceOf(Agent),
  isDemoActive: PropTypes.bool,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  text: PropTypes.any,
};

function mapStateToProps({ getProvider, isFeatureEnabled }, props) {
  const agent = getProvider('agents').findBy({ id: _.get(props, 'item.initiator.id') });
  return {
    ...props,
    agent,
    isDemoActive: isFeatureEnabled('demoMode'),
  };
}

const ApiMetadataContainer = connect(mapStateToProps)(ApiMetadata);
export default ApiMetadataContainer;
