import CustomerView from 'models/location/customer_view';

/**
 * Convenience helper that simplifies getting access to the current customer's state (customer ID and loading state)
 *
 * @param {Function} getProvider - provider "getter", typically supplied by "mapStateToProps" method
 * @returns {{customerId: *, isCustomerLoaded: (boolean)}} - Note that {customerId} may be `undefined`
 */
export function getCurrentCustomerState(getProvider) {
  const currentLocation = getProvider('currentLocation').get();
  const customersProvider = getProvider('customers');
  const isCustomerView = currentLocation instanceof CustomerView;
  const customerId = (isCustomerView && currentLocation?.customerId) || undefined;
  const isCustomerLoaded = !!customerId && customersProvider.has(customerId);

  return {
    customerId,
    isCustomerLoaded,
  };
}
