import CustomerView from 'models/location/customer_view';
import ExternalAppCardData from 'models/external_apps/external_app_card_data';
import requestCustomerExtension from 'actions/external_customer_lookup/request_customer_extension';
import reloadExternalData from 'actions/external_data/reload_external_data';
import RequestAppCardData from 'actions/external_apps/request_app_card_data';

export default class RefreshExternalCustomerData {
  constructor(context) {
    this.context = context;
  }

  run({ shouldRequestAppDataRefresh, shouldRequestCustomerExtension, shouldRequestExternalData }) {
    const currentLocation = this.context.stores.currentLocation.get();
    if (!(currentLocation instanceof CustomerView)) {
      return;
    }

    if (shouldRequestCustomerExtension) {
      requestCustomerExtension(this.context, currentLocation.customerId);
    }

    if (shouldRequestExternalData) {
      reloadExternalData(this.context, currentLocation.customerId, true);
    }

    if (shouldRequestAppDataRefresh) {
      this.context.executeAction(RequestAppCardData, {
        gladlyEntityId: currentLocation.customerId,
        gladlyEntityType: ExternalAppCardData.EntityType.CUSTOMER,
        forceDataRefresh: true,
      });
    }

    this.context.analytics.track('Customer Profile Data Refreshed', {
      customerId: currentLocation.customerId,
    });
  }
}
