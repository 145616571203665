import React from 'react';
import PropTypes from 'prop-types';
import PopoverMenu from 'components/common/menu';
import TranslateIntoMenuItem from 'components/customer/conversation_history/conversation_items_v2/menu/translate_into/translate_into_menu_item';

export default function AiTranslateIntoContextMenu({
  isOpen,
  onClose,
  position,
  targetRef,
  handleSelectLanguage,
  lastSelectedLanguage,
}) {
  return (
    // Use PopoverMenu to ensure the menu overlays the composer
    <PopoverMenu
      boundByWindow
      className="translateMenu"
      data-aid="translate-into-menu"
      isOpen={isOpen}
      onClose={onClose}
      position={position}
      targetRef={targetRef}
    >
      <TranslateIntoMenuItem
        data-aid="aiTranslateButton-translateIntoMenu"
        lastSelectedLanguage={lastSelectedLanguage}
        onClose={onClose}
        onSelect={handleSelectLanguage}
      />
    </PopoverMenu>
  );
}

AiTranslateIntoContextMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  position: PropTypes.string.isRequired,
  targetRef: PropTypes.object,
  handleSelectLanguage: PropTypes.func.isRequired,
  lastSelectedLanguage: PropTypes.string.isRequired,
};
