import useIsFeatureEnabled from 'components/hooks/use_is_feature_enabled';

export default function useConditionalTranslation(item, displayOriginal = false) {
  return useShouldTranslate(item, displayOriginal) ? item.translation.content : item.content.getMessageText();
}

export function useShouldTranslate(item, displayOriginal = false) {
  const isTranslationAIEnabled = useIsFeatureEnabled()('translationAI');
  return isTranslationAIEnabled && item.translation?.content && !displayOriginal;
}
