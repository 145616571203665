import ClearAnswerSearchResult from 'actions/customer/clear_answer_search_result';
import ClearCompositionStore from 'actions/knowledge_base/clear_composition_store';
import ClearSnippetErrors from 'actions/knowledge_base/clear_snippet_errors';
import ClearSnippetRevisionsStore from 'actions/knowledge_base/clear_snippet_revisions_store';
import FetchSnippetRevisions from 'actions/knowledge_base/fetch_snippet_revisions';
import KbAdmin from 'models/location/kb_admin';
import OpenKbAdmin from 'actions/knowledge_base/open_kb_admin';
import { registerUrlConverter } from './location_url';
import SearchAnswers from 'actions/knowledge_base/search_answers';
import SnippetEditor from 'models/location/snippet_editor';
import SwitchSnippet from 'actions/knowledge_base/switch_snippet';
import SwitchSnippetNew from 'actions/knowledge_base/switch_snippet_new';
import SwitchSnippetRecommendedAnswer from 'actions/knowledge_base/switch_snippet_recommended_answer';
import { getUrl } from 'scripts/adapters/lib/url';

registerUrlConverter(KbAdmin, {
  generateUrl: (path, params) => {
    return getUrl(path, params, { arrayFormat: 'comma' });
  },
  getPath: () => '/kb-admin',
  getQueryParams: location => {
    let params = {};
    if (location.query) {
      params.q = location.query;
    }
    if (location.language) {
      params.lng = location.language;
    }
    if (location.channel) {
      params.channel = location.channel;
    }
    if (location.audiences && location.audiences.length) {
      params.audiences = location.audiences;
    }
    return params;
  },
});
registerUrlConverter(SnippetEditor, {
  getPath: se => `/kb-admin/answer/${se.snippetId || 'new'}`,
  getQueryParams: location => {
    let params = {};
    if (location.recommendedAnswerId) {
      params.raid = location.recommendedAnswerId;
    }
    if (location.referencedAnswerId) {
      params.ref = location.referencedAnswerId;
    }
    return params;
  },
});

export default function(router, { runnerContext }) {
  return {
    '/?$': {
      on: () => {
        let queryParams = router.parseQueryParameters().toJS();
        let attr = {};
        if (queryParams.q) {
          attr.query = queryParams.q;
        }
        if (queryParams.lng) {
          attr.language = queryParams.lng;
        }
        if (queryParams.channel) {
          attr.channel = queryParams.channel;
        }
        if (queryParams.audiences) {
          attr.audiences = queryParams.audiences.split(',');
        }

        let searchPagination = {
          from: 0,
          size: 100,
        };
        let snippetSearchResult = runnerContext.stores.snippetSearchResult.get();
        if (snippetSearchResult && snippetSearchResult.query) {
          searchPagination = {
            from: snippetSearchResult.query.from,
            size: snippetSearchResult.query.size,
          };
        }

        runnerContext.executeAction(OpenKbAdmin, attr);
        runnerContext.executeAction(SearchAnswers, {
          searchText: attr.query,
          language: attr.language,
          channel: attr.channel,
          audienceIds: attr.audiences,
          ...searchPagination,
        });
      },
      after: () => {
        runnerContext.executeAction(ClearCompositionStore);
        runnerContext.executeAction(ClearSnippetErrors);
        runnerContext.executeAction(ClearSnippetRevisionsStore);
        runnerContext.executeAction(ClearAnswerSearchResult);
      },
    },
    '/answer/new': {
      on: () => {
        let queryParams = router.parseQueryParameters().toJS();
        if (queryParams.raid) {
          runnerContext.executeAction(SwitchSnippetRecommendedAnswer, {
            recommendedAnswerId: queryParams.raid,
            referencedAnswerId: queryParams.ref,
          });
        } else {
          runnerContext.executeAction(SwitchSnippetNew, { referencedAnswerId: queryParams.ref });
        }
      },
      after: () => {
        runnerContext.executeAction(ClearCompositionStore);
        runnerContext.executeAction(ClearSnippetErrors);
      },
    },
    '/answer/:answerId': {
      on: answerId => {
        let queryParams = router.parseQueryParameters().toJS();
        runnerContext.executeAction(SwitchSnippet, { referencedAnswerId: queryParams.ref, snippetId: answerId });
        runnerContext.executeAction(FetchSnippetRevisions, answerId);
      },
      after: () => {
        runnerContext.executeAction(ClearCompositionStore);
        runnerContext.executeAction(ClearSnippetErrors);
      },
    },
  };
}
