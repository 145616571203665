import React from 'react';

import Icon from 'components/lib/icons/icon';

const SidekickIcon = props => {
  return (
    <Icon fill="none" viewBox="0 0 32 32" {...props}>
      <g id="sidekick icon top nav toggle">
        <g id="sidekick icon top nav interaction">
          <rect fill="#919191" height="32" rx="16" width="32" />
          <g id="Group 2383">
            <rect fill="white" height="6.15385" id="Rectangle 12876" width="1.23077" x="15.3845" y="8" />
            <rect fill="white" height="6.15385" id="Rectangle 12883" width="1.23077" x="15.3845" y="17.8462" />
            <rect
              fill="white"
              height="3.10039"
              id="Rectangle 12884"
              transform="rotate(-45 18.7021 19.5286)"
              width="1.16843"
              x="18.7021"
              y="19.5286"
            />
            <rect
              fill="white"
              height="3.12398"
              id="Rectangle 12877"
              transform="rotate(-45 10.3433 11.2134)"
              width="1.23077"
              x="10.3433"
              y="11.2134"
            />
            <rect
              fill="white"
              height="3.03325"
              id="Rectangle 12878"
              transform="rotate(-135 11.2134 21.6567)"
              width="1.23077"
              x="11.2134"
              y="21.6567"
            />
            <rect
              fill="white"
              height="3.07605"
              id="Rectangle 12882"
              transform="rotate(-135 19.4817 13.3884)"
              width="1.23077"
              x="19.4817"
              y="13.3884"
            />
            <rect
              fill="white"
              height="6.15385"
              id="Rectangle 12879"
              transform="rotate(90 14.1538 15.3845)"
              width="1.23077"
              x="14.1538"
              y="15.3845"
            />
            <rect
              fill="white"
              height="6.15385"
              id="Rectangle 12880"
              transform="rotate(90 24 15.3845)"
              width="1.23077"
              x="24"
              y="15.3845"
            />
          </g>
        </g>
      </g>
    </Icon>
  );
};

SidekickIcon.proptypes = {
  ...Icon.proptypes,
};

export default SidekickIcon;
