import _ from 'lodash';

import createModel, { prop } from './lib/create_model';
import createEnum from 'scripts/lib/create_enum';

const VoiceAIMessageDeliveryStatus = createEnum('PLAYED', 'INTERRUPTED');

const VoiceAIMessage = createModel({
  modelName: 'VoiceAutomation',

  properties: {
    automationSessionId: prop(String),
    callId: prop(String),
    customerNumber: prop(String),
    companyPhoneNumber: prop(String),
    body: prop(String),
    status: prop().oneOf(..._.values(VoiceAIMessageDeliveryStatus)),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },

  getMessageText() {
    return this.body;
  },
});

export default VoiceAIMessage;
