import classnames from 'classnames';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import BubbleContent from '../content/bubble_content';
import ConversationItem from 'models/conversation_item';
import FbMessageAttachmentsContainer from './attachments/fb_message_attachments';
import ItemCommError from '../item_comm_error';
import ItemContent from '../item_content';
import ItemContext from '../item_context';
import ItemMetadata from '../item_metadata';
import { Status as FbMessageOutgoingStatus } from 'models/fb_message_outgoing';
import Linkifier from 'components/lib/linkifier';
import useConditionalTranslation from 'components/hooks/use_conditional_translation';

export default function FacebookOutgoingItem({ className, isFirstInStack, isLastInStack, isStacked, item }) {
  return (
    <ItemContent
      isFirstInStack={isFirstInStack}
      isLastInStack={isLastInStack}
      isStacked={isStacked}
      item={item}
      showIcon={!isStacked}
    >
      <ItemMetadata item={item} showMetadata={!isStacked} status={getStatus(item)} />
      <FacebookOutgoingContent className={className} item={item} />
      <ItemCommError item={item} />
    </ItemContent>
  );

  function getStatus() {
    const status = item.content.getStatus();
    switch (status) {
      case FbMessageOutgoingStatus.FAILED:
        return 'delivery failed';
      case FbMessageOutgoingStatus.NEW:
        return 'sending';
      case FbMessageOutgoingStatus.SENT:
        return 'delivered';
      default:
        return undefined;
    }
  }
}

FacebookOutgoingItem.propTypes = {
  className: PropTypes.string,
  isFirstInStack: PropTypes.bool,
  isLastInStack: PropTypes.bool,
  isStacked: PropTypes.bool,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};

export function FacebookOutgoingContent({ className, item }) {
  return (
    <BubbleContent
      className={classnames('facebookOutgoingItem-content', className)}
      data-aid={`facebookOutgoingItem-${item?.id}`}
      item={item}
    >
      {<MessageText />}
      {renderAttachment()}
    </BubbleContent>
  );

  function MessageText() {
    const { isViewTranslation } = useContext(ItemContext);
    const text = useConditionalTranslation(item, !isViewTranslation);
    if (!text) {
      return null;
    }

    return (
      <bdi>
        <div>
          <Linkifier>{text}</Linkifier>
        </div>
      </bdi>
    );
  }

  function renderAttachment() {
    const attachment = item.content.request.message.attachment;
    if (!attachment) {
      return null;
    }

    return <FbMessageAttachmentsContainer attachments={[attachment]} />;
  }
}

FacebookOutgoingContent.propTypes = {
  className: PropTypes.string,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};
