import moment from 'moment';
import orderBy from 'lodash/orderBy';

import { CommunicationSessionStatus } from 'models/agent_activity';
import Communicator from 'models/communicator';
import Conversation from 'models/conversation';
import ConversationItemType from 'models/conversation_item_type';
import { getLatestManualItem, isCustomerItem } from 'scripts/application/lib/conversation_history_helpers';
import InteractionType, { baseInteractionType } from 'models/interaction_type';
import PhoneCall from 'models/phone_call';

const interactionTypeRank = Object.freeze([
  InteractionType.TASK,
  InteractionType.ABANDONED_CALL,
  InteractionType.VOICEMAIL,
  InteractionType.EMAIL,
  InteractionType.CUSTOM_CHANNEL,
  InteractionType.WHATSAPP,
  InteractionType.TWITTER,
  InteractionType.FB_MESSENGER,
  InteractionType.SMS,
  InteractionType.CHAT,
  InteractionType.PHONE_CALL,
]);

export function getActiveConversationAttributes(conversationsStore, selectAttributes) {
  return conversationsStore.findBy({ filter: c => c.status !== Conversation.Status.CLOSED, select: selectAttributes });
}

export function getActiveConversationId(conversationsStore) {
  const activeConversation = getActiveConversationAttributes(conversationsStore, ['id']);
  return activeConversation?.id ?? null;
}

export function getCommunicationSession(customerId, stores) {
  const { activeCall, activeSessions, customers } = stores;

  const activeCallSession = activeCall.get();
  const activeCallSessionId = activeCallSession?.conversationItem?.id;

  if (activeCallSessionId) {
    return { id: activeCallSessionId, type: InteractionType.PHONE_CALL, status: CommunicationSessionStatus.ACTIVE };
  }

  const activeSession = activeSessions.findBy({ customer: { id: customerId } });
  if (activeSession) {
    const session = getCommunicationSessionFromActiveSession(activeSession);
    if (!session) {
      return null;
    }

    return {
      id: session.id,
      type: session.type,
      status: CommunicationSessionStatus.ACTIVE,
    };
  }

  if (customerId && customers.has({ id: customerId })) {
    const { conversations, conversationHistory } = customers.storesFor(customerId);

    const sessionFromHistory = getLastSessionFromHistory(conversations, conversationHistory);
    if (sessionFromHistory) {
      return {
        id: sessionFromHistory.id,
        type: sessionFromHistory.type,
        status: CommunicationSessionStatus.ENDED,
      };
    }
  }
  return null;
}

export function getConversationAssignee(conversation) {
  const { agentId, routingGroupId: groupId } = conversation?.assignee || {};

  return { agentId, groupId };
}

/**
 * Get the customer language, if set, or the language of a translation of the last inbound conversation item.
 * If neither is available, returns undefined.
 * @param conversationsStore
 * @param conversationHistoryStore
 * @param customerLanguageStore
 * @returns {undefined|string}
 */
export function getCustomerLanguageCode({ conversationsStore, conversationHistoryStore, customerLanguageStore }) {
  const customerLanguage = customerLanguageStore.get();
  if (customerLanguage) {
    return customerLanguage.code;
  }

  const conversationId = getLatestConversationId(conversationsStore);
  if (!conversationId) {
    return undefined;
  }

  const lastCustomerItem = getLatestManualItem({
    conversationHistory: conversationHistoryStore,
    conversationId,
    filter: isCustomerItem,
  });

  return lastCustomerItem?.translation?.parentLanguage;
}

export function getLatestConversationAttributes(conversationsStore, selectAttributes) {
  const latestConversation = conversationsStore.findBy({
    select: selectAttributes,
    sortBy: c => -Date.parse(c.createdAt),
  });

  return getActiveConversationAttributes(conversationsStore, selectAttributes) || latestConversation || null;
}

export function getLatestConversationId(conversationsStore) {
  const latestConversation = getLatestConversationAttributes(conversationsStore, ['id']);
  return latestConversation?.id ?? null;
}

export function getLastSessionFromHistory(conversations, conversationHistory) {
  const currentConversationId = getActiveConversationId(conversations);
  if (!currentConversationId) {
    return null;
  }

  const lastCustomerMessage = conversationHistory.findBy({
    filter: item =>
      item.conversationId === currentConversationId &&
      item.initiator.type === Communicator.CUSTOMER &&
      !isCommunicationSessionEndedItem(item),
    sortBy: item => -Date.parse(item.timestamp),
  });

  const lastSessionEndedItem = conversationHistory.findBy({
    filter: item => isCommunicationSessionEndedItem(item),
    sortBy: item => -Date.parse(item.timestamp),
  });

  if (!lastSessionEndedItem) {
    return null;
  }

  if (!lastCustomerMessage || moment(lastSessionEndedItem?.timestamp).isAfter(lastCustomerMessage?.timestamp)) {
    return lastSessionEndedItem.content instanceof PhoneCall
      ? { id: lastSessionEndedItem.id, type: InteractionType.PHONE_CALL }
      : { id: lastSessionEndedItem.content.sessionId, type: lastSessionEndedItem.content.sessionType };
  }

  return null;
}

export function getSortedConversations(conversationsStore) {
  const closedConversations = conversationsStore.findAll({
    filter: { status: Conversation.Status.CLOSED },
    select: ['id', 'status', 'createdAt'],
    sortBy: c => Date.parse(c.createdAt),
  });

  const activeConversation = conversationsStore.findBy({
    filter: c => c.status !== Conversation.Status.CLOSED,
    select: ['id', 'status', 'createdAt'],
  });

  if (activeConversation) {
    return closedConversations.concat(activeConversation);
  }

  return closedConversations;
}

function getCommunicationSessionFromActiveSession(activeSession) {
  return orderBy(activeSession.queueItem.sessions, [session => getInteractionTypeRank(session.type)], ['desc'])[0];
}

function getInteractionTypeRank(interactionType) {
  return interactionTypeRank.indexOf(baseInteractionType(interactionType));
}

function isCommunicationSessionEndedItem(item) {
  return (
    (item.content.type === ConversationItemType.PHONE_CALL && item.content.status === PhoneCall.Status.COMPLETED) ||
    item.content.type === ConversationItemType.SESSION_ENDED
  );
}
