import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import BubbleContent from '../content/bubble_content';
import ConversationItem from 'models/conversation_item';
import ConversationMessageAttachments from 'components/customer/conversation_history/conversation_items_v2/message/attachments/conversation_message_attachments';
import InlineAnnotatedContentContainer from 'components/lib/inline_annotated_content_container';
import ItemContext from '../item_context';
import useConditionalTranslation from 'components/hooks/use_conditional_translation';

export default function ChatTextContent({ className, item, status }) {
  const { isViewTranslation } = useContext(ItemContext);
  const text = useConditionalTranslation(item, !isViewTranslation);
  return (
    <BubbleContent
      className={classnames('chatItem-content text', className)}
      data-aid={`chatItem-content-${item.id}`}
      item={item}
      status={status}
    >
      <InlineAnnotatedContentContainer id={item.id} isRedacted={item.content.isRedacted} text={text} />
      {renderAttachments()}
    </BubbleContent>
  );

  function renderAttachments() {
    let itemAttachments = item.content.attachments;
    if (!itemAttachments || itemAttachments.length === 0) {
      return null;
    }
    return <ConversationMessageAttachments attachments={itemAttachments} />;
  }
}

ChatTextContent.propTypes = {
  className: PropTypes.string,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  status: PropTypes.string,
};
