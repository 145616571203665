import _ from 'lodash';
import classnames from 'classnames';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { CHANNEL_ACTION_TEXT_WITH_SUBJECT } from 'components/lib/channel_action_text';
import BubbleContent from '../content/bubble_content';
import Communicator from 'models/communicator';
import connect from 'components/lib/connect';
import ConversationItem from 'models/conversation_item';
import CustomChannel from 'models/custom_channel';
import ConversationItemType from 'models/conversation_item_type';
import { customChannelMessageStatusCode } from 'models/custom_channel_message';
import InlineAnnotatedContentContainer from 'components/lib/inline_annotated_content_container';
import ItemCommError from '../item_comm_error';
import ItemContent from '../item_content';
import ItemContext from '../item_context';
import ItemMetadata from '../item_metadata';
import CustomChannelMessageAttachments from './custom_channel_message_attachments';
import { CustomChannelMessageContentFiles, CustomChannelMessageContentText } from 'models/custom_channel_message';
import { getBrandIconByName } from 'components/common/custom_channels';
import styled from 'styled-components';
import RedactedItem from 'components/customer/conversation_history/conversation_items_v2/redacted_item';
import { useShouldTranslate } from 'components/hooks/use_conditional_translation';

export function CustomChannelMessageItem({
  className,
  customChannel,
  isFirstInStack,
  isHighlighted,
  isLastInStack,
  isStacked,
  item,
  subject,
}) {
  const icon = <>{getBrandIconByName(customChannel?.icon, { letter: customChannel?.name, stroke: true }).icon}</>;
  let text = getText({ customChannel, groupName: item.content.groupName });

  return (
    <ItemContent
      isFirstInStack={isFirstInStack}
      isItemGroupHighlighted={isHighlighted}
      isLastInStack={isLastInStack}
      isStacked={isStacked}
      item={item}
      showIcon={!isStacked}
    >
      <ItemMetadata
        icon={icon}
        item={item}
        showMetadata={!isStacked}
        status={getStatus()}
        subject={subject}
        text={text}
      />
      <CustomChannelMessageContent className={className} isHighlighted={isHighlighted} item={item} />
      <ItemCommError item={item} />
    </ItemContent>
  );

  function getText({ customChannel, groupName }) {
    const text = CHANNEL_ACTION_TEXT_WITH_SUBJECT[ConversationItemType.CUSTOM_CHANNEL_MESSAGE].replace(
      '%channel_name%',
      customChannel?.name
    );

    if (item.content.groupName) {
      return (
        <>
          {text} about <StyledStrong>{item.content.groupName}</StyledStrong>
        </>
      );
    }

    return text;
  }

  function getStatus() {
    const status = item.content.status?.value;
    switch (status) {
      case customChannelMessageStatusCode.ACCEPTED:
        return 'accepted';
      case customChannelMessageStatusCode.DELIVERED:
        return 'delivered';
      case customChannelMessageStatusCode.FAILED:
        return 'delivery failed';
      case customChannelMessageStatusCode.READ:
        return 'read';
      case customChannelMessageStatusCode.SENDING:
        return 'sending';
      case customChannelMessageStatusCode.SENT:
        return 'sent';
      default:
        return undefined;
    }
  }
}

CustomChannelMessageItem.propTypes = {
  className: PropTypes.string,
  customChannel: PropTypes.instanceOf(CustomChannel),
  isFirstInStack: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  isLastInStack: PropTypes.bool,
  isStacked: PropTypes.bool,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  subject: PropTypes.string,
};

function mapStateToProps({ getProvider }, props) {
  const profile = getProvider('profile').get();
  const customChannels = getProvider('customChannels').findAll();

  const { customChannelId, customerAddress } = props.item.content;
  const customChannel = _.find(customChannels, { id: customChannelId });

  const externalCustomerAddresses = profile.getExternalCustomerAddresses();
  const externalAddress = _.find(externalCustomerAddresses, { id: customerAddress, type: 'CUSTOM_CHANNEL' });

  const subject = props.item.initiator.type === Communicator.AGENT ? null : externalAddress?.displayName;

  const highlightedMessages = getProvider('highlightedMessages').get();
  const isHighlighted =
    highlightedMessages?.id === props.item.content?.groupId && highlightedMessages?.customerId === profile.id;

  return {
    ...props,
    customChannel,
    isHighlighted,
    subject,
  };
}

const CustomChannelMessageItemContainer = connect(mapStateToProps)(CustomChannelMessageItem);

export function CustomChannelMessageContent({ className, isHighlighted, item }) {
  const { customChannelId, isRedacted } = item.content;

  return (
    <BubbleContent
      className={classnames('customChannelMessage-content', className)}
      data-aid={`customChannelMessage-${item.id}`}
      isHighlighted={isHighlighted}
      item={item}
    >
      <ContentElement
        customChannelId={customChannelId}
        isRedacted={isRedacted}
        item={item}
        messageContent={item.content.content}
      />
    </BubbleContent>
  );
}

CustomChannelMessageContent.propTypes = {
  className: PropTypes.string,
  isHighlighted: PropTypes.bool,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};

function ContentElement({ item, messageContent, customChannelId, isRedacted }) {
  const { isViewTranslation } = useContext(ItemContext);
  const shouldTranslate = useShouldTranslate(item, !isViewTranslation);
  if (isRedacted) {
    return <RedactedItem />;
  }

  if (messageContent instanceof CustomChannelMessageContentFiles) {
    return (
      <>
        <InlineAnnotatedContentContainer id={item.id} text={messageContent.caption} />
        <CustomChannelMessageAttachments
          attachments={messageContent.attachments || []}
          customChannelId={customChannelId}
          itemId={item.id}
        />
      </>
    );
  } else if (messageContent instanceof CustomChannelMessageContentText) {
    return (
      <>
        <InlineAnnotatedContentContainer
          id={item.id}
          text={shouldTranslate ? item.translation.content : messageContent.text}
        />
      </>
    );
  }

  return null;
}

ContentElement.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  messageContent: PropTypes.instanceOf(CustomChannelMessageContentText),
  customChannelId: PropTypes.string,
  isRedacted: PropTypes.bool,
};

export const StyledStrong = styled.strong`
  font-weight: 700;
`;

export default CustomChannelMessageItemContainer;
