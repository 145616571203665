import _ from 'lodash';
import classnames from 'classnames';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import BubbleContent from '../content/bubble_content';
import ConversationItem from 'models/conversation_item';
import FbMessageAttachmentContainer from './attachments/fb_message_attachment_container';
import FbMessageAttachmentsContainer from './attachments/fb_message_attachments';
import FbStickerContainer from './attachments/fb_sticker_container';
import InlineAnnotatedContentContainer from 'components/lib/inline_annotated_content_container';
import ItemContent from '../item_content';
import ItemContext from '../item_context';
import ItemMetadata from '../item_metadata';
import Link from 'components/common/link';
import useConditionalTranslation from 'components/hooks/use_conditional_translation';

export default function FacebookIncomingItem({ className, isFirstInStack, isLastInStack, isStacked, item }) {
  return (
    <ItemContent
      isFirstInStack={isFirstInStack}
      isLastInStack={isLastInStack}
      isStacked={isStacked}
      item={item}
      showIcon={!isStacked}
    >
      <ItemMetadata item={item} showMetadata={!isStacked} />
      <FacebookIncomingContent className={className} item={item} />
    </ItemContent>
  );
}

FacebookIncomingItem.propTypes = {
  className: PropTypes.string,
  isFirstInStack: PropTypes.bool,
  isLastInStack: PropTypes.bool,
  isStacked: PropTypes.bool,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};

export function FacebookIncomingContent({ className, item }) {
  const { isViewTranslation } = useContext(ItemContext);
  const text = useConditionalTranslation(item, !isViewTranslation);

  const attachments = _.get(item, 'content.message.attachments', []);
  const sticker = attachments.length === 1 && attachments[0].isSticker() && attachments[0];
  const links = _.get(item, 'content.message.links', []);

  return (
    <BubbleContent
      className={classnames('facebookIncomingItem-content', className)}
      data-aid={`facebookIncomingItem-${item?.id}`}
      item={item}
    >
      {renderLinks()}
      {renderText()}
      {renderAttachments()}
      {renderSticker()}
    </BubbleContent>
  );

  function renderText() {
    if (!text && !item.content.isRedacted) {
      return null;
    }

    return (
      <div dir="auto">
        <InlineAnnotatedContentContainer
          hasRedactedPaymentCard={item.content.hasRedactedPaymentCardNumber}
          id={item.id}
          isRedacted={item.content.isRedacted}
          text={text}
        />
      </div>
    );
  }

  function renderLinks() {
    if (!links.length) {
      return null;
    }

    let externalLinks = [];
    links.forEach((link, idx) => {
      let title = link.title || link.url;
      externalLinks.push(
        <Link href={link.url} key={`${link.url}-${idx}`} onClick={() => window.open(link.url, '_blank')}>
          {title}
        </Link>
      );
    });

    return externalLinks;
  }

  function renderAttachments() {
    if (!attachments.length || sticker) {
      return null;
    }
    return <FbMessageAttachmentsContainer attachments={attachments} conversationItemId={item.id} />;
  }

  function renderSticker() {
    if (!sticker) {
      return null;
    }

    if (!sticker.isAvailable()) {
      return <FbMessageAttachmentContainer attachment={sticker} />;
    }

    return <FbStickerContainer attachment={sticker} />;
  }
}

FacebookIncomingContent.propTypes = {
  className: PropTypes.string,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};
