import Language from 'scripts/domain/models/language';
import ErrorReporter from 'scripts/infrastructure/error_reporter';

export const STORAGE_KEY = 'language-selected-language';

export default class LocalLanguageStore {
  constructor(storage) {
    this.storage = storage;
  }

  get() {
    const json = this.storage.getItem(STORAGE_KEY);
    try {
      return json ? Language.fromJs(JSON.parse(json)) : null;
    } catch (e) {
      ErrorReporter.reportError(e, {
        message: 'Error parsing language from local storage',
      });
      return null;
    }
  }

  getProvider() {
    return { get: this.get.bind(this) };
  }

  set(language) {
    try {
      this.storage.setItem(STORAGE_KEY, JSON.stringify(language.toJs()));
    } catch (e) {
      ErrorReporter.reportError(e, {
        message: 'Error setting language in local storage',
      });
    }
  }

  remove() {
    this.language = null;
    this.storage.removeItem(STORAGE_KEY);
  }
}
