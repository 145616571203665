import AbandonedCall from 'models/abandoned_call';
import AIActivityItem from 'models/ai_activity/ai_activity_item';
import ChatCustomerUrl from 'models/chat_customer_url';
import ChatMessage from 'models/chat_message';
import Communicator from 'models/communicator';
import ContentPlaceholder from 'models/content_placeholder';
import ConversationMessage from 'models/conversation_message';
import ConversationNote from 'models/conversation_note';
import CustomChannelMessage from 'models/custom_channel_message';
import CustomerActivity from 'models/customer_activity';
import EmailCampaignOutreach from 'models/email_campaign_outreach';
import EmailMessage from 'models/email_message';
import EmailPreview from 'models/content_preview/email_preview';
import EmailSendFailure from 'models/email_send_failure';
import FbMessageIncoming from 'models/fb_message_incoming';
import FbMessageOutgoing from 'models/fb_message_outgoing';
import getClassName from 'scripts/lib/class_name';
import { isDevelopment } from 'scripts/application/lib/environment';
import MessageAutomationSummaryContent, {
  MessageAutomationContent,
  MessageAutomationItemContent,
} from 'models/message_automation_content';
import PaymentStatusEventContent from 'models/payment_status_event_content';
import PhoneCall from 'models/phone_call';
import PhoneCallback from 'models/phone_callback';
import SmsCampaignOutreach from 'models/sms_campaign_outreach';
import SmsMessage from 'models/sms_message';
import Task from 'models/task';
import TaskCompletionContent from 'models/task_completion_content';
import TaskReopenedContent from 'models/task_reopened_content';
import Tweet from 'models/tweet';
import SecureDataRequest from 'models/secure_data_request';
import Voicemail from 'models/voicemail';
import WebForm from 'models/web_form';
import WidgetActivity from 'models/widget_activity';
import VoiceAIMessage from 'models/voiceai_message';
import VoiceAIRecording from 'models/voiceai_recording';

export function getTopicName(conversation, topicsProvider) {
  if (!conversation.topicId) {
    return null;
  }

  let topic = topicsProvider.findBy({ id: conversation.topicId });
  return topic && topic.name;
}

export function getDefaultTopicName(firstItem, lastManualItem) {
  // LOADING STATE
  if (firstItem && firstItem.content instanceof ContentPlaceholder) {
    return 'Loading conversation';
  }

  // NO MANUAL CONVERSATION ITEMS
  if (!lastManualItem) {
    return 'New conversation';
  }

  return getTopicNameFromItem(lastManualItem);
}

export function getTopicNameFromItem(conversationItem, customChannelNames) {
  if (!conversationItem) {
    return 'New Conversation';
  }
  let content = conversationItem.content;

  let direction;
  if (conversationItem.initiator.type === Communicator.CUSTOMER) {
    direction = 'Incoming';
  } else {
    direction = 'Outgoing';
  }
  if (content instanceof AbandonedCall) {
    return 'Abandoned Call';
  } else if (content instanceof AIActivityItem) {
    return 'AI Activity';
  } else if (content instanceof MessageAutomationSummaryContent) {
    return 'Message Automation Summary';
  } else if (content instanceof MessageAutomationItemContent) {
    return 'Message Automation Item';
  } else if (content instanceof MessageAutomationContent) {
    return 'Message Automation Content';
  } else if (content instanceof ChatMessage) {
    return `${direction} Chat`;
  } else if (content instanceof ChatCustomerUrl) {
    return 'Chat Customer URL';
  } else if (content instanceof ConversationMessage) {
    return `${direction} ${content.getChannelName()}`;
  } else if (content instanceof ConversationNote) {
    return 'Note';
  } else if (content instanceof CustomChannelMessage) {
    const customChannelName = customChannelNames && customChannelNames[content.customChannelId];
    if (!customChannelName) {
      return `${direction} Custom Channel Message`;
    }
    return `${direction} ${customChannelName} Message`;
  } else if (content instanceof CustomerActivity) {
    let sourceName = content.sourceName || 'Customer';
    return `${sourceName} Activity`;
  } else if (content instanceof EmailCampaignOutreach) {
    return `${direction} Email`;
  } else if (content instanceof EmailMessage) {
    return `${direction} Email`;
  } else if (content instanceof EmailSendFailure) {
    return `Email Send Failure`;
  } else if (content instanceof EmailPreview) {
    return `${direction} Email`;
  } else if (content instanceof FbMessageIncoming) {
    return 'Incoming Facebook Message';
  } else if (content instanceof FbMessageOutgoing) {
    return 'Outgoing Facebook Message';
  } else if (content instanceof PhoneCall) {
    return `${direction} Call`;
  } else if (content instanceof PhoneCallback) {
    return 'Call Customer Back';
  } else if (content instanceof SmsCampaignOutreach) {
    return `${direction} SMS`;
  } else if (content instanceof SmsMessage) {
    return `${direction} SMS`;
  } else if (content instanceof Task) {
    return 'Task Created';
  } else if (content instanceof TaskCompletionContent) {
    return 'Task Closed';
  } else if (content instanceof TaskReopenedContent) {
    return 'Task Reopened';
  } else if (content instanceof Tweet) {
    if (direction === 'Incoming') {
      return `@${content.user.screenName}`;
    }
    return `${direction} Tweet`;
  } else if (content instanceof SecureDataRequest) {
    return 'Credit Card Request';
  } else if (content instanceof PaymentStatusEventContent) {
    return 'Credit Card Request';
  } else if (content instanceof Voicemail) {
    return 'Voicemail';
  } else if (content instanceof WebForm) {
    return 'Incoming Web Form';
  } else if (content instanceof WidgetActivity) {
    return 'Widget Activity';
  } else if (content instanceof VoiceAIMessage) {
    return 'Voice AI Message';
  } else if (content instanceof VoiceAIRecording) {
    return 'Voice AI Recording';
  }

  const devMode = isDevelopment();
  if (!devMode) return null; // Do not break UI in prod over some unknown conversation item. Doing it in dev is OK

  throw new Error(`invalid conversation content [${getClassName(content)}]`);
}
