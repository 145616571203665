import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import AnswerPerformanceView from 'models/location/answer_performance_view';
import Button, { ButtonTypes } from 'components/common/button';
import connect from 'components/lib/connect';
import DismissRecommendedAnswer from 'actions/recommended_answers/dismiss_recommended_answer';
import FullPageSpinner from 'components/common/full_page_spinner';
import { H2, H3 } from 'components/common/headers';
import NavigateTo from 'actions/current_location/navigate_to';
import PageLayout from 'components/page_layout';
import SnippetEditor from 'models/location/snippet_editor';
import StackedBooksImage from 'components/recommended_answers/stacked_books_image';

export function RecommendedAnswers({
  isLoading,
  recommendedAnswers,
  onAddNewAnswer,
  onDismiss,
  navigateToAnswerPerformance,
}) {
  function renderEmptyState() {
    return (
      <EmptyStateContainer data-aid="recommendedAnswers-emptyStateContainer">
        <StackedBooksImage data-aid="recommendedAnswers-stackedBooksImage" />
        <EmptyStateBoldText>No Recommended Answers found</EmptyStateBoldText>
        <p>
          Back to{' '}
          <StyledLink data-aid="recommendedAnswers-backToAnswerPerformance" onClick={navigateToAnswerPerformance}>
            Answers Performance
          </StyledLink>
        </p>
      </EmptyStateContainer>
    );
  }

  function renderList() {
    const referenceAnswer = recommendedAnswers[0].answer;
    const countRecommendedAnswers = `${recommendedAnswers.length} Recommended Answer${
      recommendedAnswers.length > 1 ? 's' : ''
    }`;
    const audienceNames = recommendedAnswers[0].audiences
      .map(a => a.name)
      .sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1))
      .join(', ');

    return (
      <>
        <H2 data-aid="recommendedAnswers-referenceAnswerName">{referenceAnswer.name}</H2>
        {audienceNames && <Bold data-aid="recommendedAnswers-audienceNames">{audienceNames}</Bold>}
        <div data-aid="recommendedAnswers-helpText-line1">
          Below you will find a set of Answers that Sidekick has created for inquiries that it was not able to resolve.
          Note that Recommended Answers
        </div>
        <div data-aid="recommendedAnswers-helpText-line2">
          are based on Customer inquiries received over the past seven days. A new batch of Recommended Answers is
          created weekly.
        </div>
        <br />
        <Bold data-aid="recommendedAnswers-countRecommendedAnswers">{countRecommendedAnswers}</Bold>
        {recommendedAnswers.map(ra => (
          <RecommendedAnswerCard data-aid="recommendedAnswers-recommendedAnswerCard" key={ra.id}>
            <Bold data-aid="recommendedAnswers-recommendedAnswerName">{ra.name}</Bold>
            <p data-aid="recommendedAnswers-recommendedAnswerContent">{ra.content}</p>
            <DismissButton
              buttonType={ButtonTypes.TEXT}
              data-aid="recommendedAnswers-dismissButton"
              onClick={onDismiss.bind(null, ra.id)}
            >
              Dismiss
            </DismissButton>
            <Button
              data-aid="recommendedAnswers-addNewAnswerButton"
              onClick={onAddNewAnswer.bind(null, ra.id, ra.answer.id)}
            >
              Add new Answer
            </Button>
          </RecommendedAnswerCard>
        ))}
      </>
    );
  }

  const renderContent = () => {
    if (isLoading) {
      return <FullPageSpinner data-aid="recommendedAnswers-loadingSpinner" />;
    }

    if (!recommendedAnswers.length) {
      return renderEmptyState();
    }

    return renderList();
  };

  return (
    <PageLayout className="recommended-answers-pageWrapper" id="recommended-answers">
      <RecommendedAnswersPage>{renderContent()}</RecommendedAnswersPage>
    </PageLayout>
  );
}

RecommendedAnswers.propTypes = {
  isLoading: PropTypes.bool,
  recommendedAnswers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      answer: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
      audiences: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
      content: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
    })
  ),
  onAddNewAnswer: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  navigateToAnswerPerformance: PropTypes.func.isRequired,
};

function mapStateToProps({ getProvider }) {
  const recommendedAnswersProvider = getProvider('recommendedAnswers');
  const recommendedAnswers = recommendedAnswersProvider.findAll();

  return {
    isLoading: recommendedAnswersProvider.isLoading(),
    recommendedAnswers: recommendedAnswers.sort((a, b) =>
      a.name.localeCompare(b.name, undefined, {
        sensitivity: 'base',
      })
    ),
  };
}

function mapExecuteToProps(executeAction) {
  return {
    onAddNewAnswer: (recommendedAnswerId, referencedAnswerId) =>
      executeAction(NavigateTo, SnippetEditor.create({ recommendedAnswerId, referencedAnswerId })),
    onDismiss: recommendedAnswerId => executeAction(DismissRecommendedAnswer, { recommendedAnswerId }),
    navigateToAnswerPerformance: () => executeAction(NavigateTo, AnswerPerformanceView.create()),
  };
}

const RecommendedAnswersContainer = connect(mapStateToProps, mapExecuteToProps)(RecommendedAnswers);

export default RecommendedAnswersContainer;

const Bold = styled(H3)`
  font-size: ${p => p.theme.fontSize.base};
`;

const DismissButton = styled(Button)`
  color: ${p => p.theme.colors.gray700};
  padding-left: 0;
`;

const EmptyStateContainer = styled.div`
  padding-top: 150px;
  text-align: center;
`;

const EmptyStateBoldText = styled.p`
  font-size: ${p => p.theme.fontSize.medium};
  font-weight: ${p => p.theme.fontWeight.heavy};
  margin-bottom: -3px;
`;

const RecommendedAnswersPage = styled.div`
  margin: 40px 64px;
  padding-bottom: 16px;
`;

const RecommendedAnswerCard = styled.div`
  border: 1px solid ${p => p.theme.colors.gray300};
  border-radius: ${p => p.theme.borderRadius.large};
  padding: ${p => {
    const medium = p.theme.spacing.insetMedium;
    const large = p.theme.spacing.insetLarge;
    return `${large} ${medium}`;
  }};
  margin-bottom: 24px;
`;

const StyledLink = styled.a`
  cursor: pointer;
`;
